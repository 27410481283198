.Kalend__CalendarHeaderCol-dark, 
.Kalend__CalendarHeader-tall-dark,
.Kalend__CalendarBody {
    background-color: oklch(var(--b1));
}

.Kalend__CalendarHeader-tall-dark,
.Kalend__CalendarHeader-tall {
    border-radius: 8px;
    box-shadow: 0px 3px 4px -1px oklch(var(--p));
}

.Kalend__CalendarHeader-tall-day-dark,
.Kalend__CalendarHeader-tall-day {
    border-radius: 8px;
    left: 0;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    height: auto;
    width: 100%;
    padding-bottom: 6px;
    box-shadow: 0px 3px 4px -1px oklch(var(--p));
    z-index: 10;
}

.Kalend__CalendarHeader-small-dark,
.Kalend__CalendarHeader-small {
    border-radius: 8px;
    left: 0;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    height: auto;
    width: 100%;
    padding-bottom: 6px;
    box-shadow: 0px 3px 4px -1px oklch(var(--p));
    z-index: 10;
}

.Kalend__CurrentHourLine,
.Kalend__CurrentHourLine-dark {
    z-index: 20 !important;
} 

.popup-overlay {
    z-index: 49 !important;
}